import React from "react";

import imgC from "../../assets/image/inner-page/webp/chapter-overview.webp";
import imgD from "../../assets/image/inner-page/webp/chapter-overview-mobile.webp";

const CTA  = ({ className, ...rest }) => {

  return (
    <div className={className} {...rest}>
      <div className="pt-14 pt-md-19 pt-lg-10 pb-15 pb-md-18 pb-lg-25 mb-lg-1">
      <div className="container">
      <div className="row align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-md-10 col-sm-11"
            data-aos="fade-right"
            data-aos-duration={500}
          >
            <img src={imgC} alt="Chapter Overview Desktop" className="w-100 rounded-5 box-shadow" />

          </div>
          <div className="col-lg-6 col-md-10 col-sm-11">
            <div className="pt-9 pt-lg-0 pr-xs-10 pr-sm-5 pr-md-25 pr-lg-0 pl-xl-16">
              <h3 className="font-size-9 mb-5 mt-10">Desktop Chapter Overview</h3>
              <p
                className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15"
                data-aos="fade-up"
                data-aos-duration={900}
              >This is the heart of the interactive video. Displaying relevant timely content to increase engagement.</p>
              <p
                className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15"
                data-aos="fade-up"
                data-aos-duration={900}
              >Clicking on the video, the user can discover in-depth information on what you are showcasing. In this example, instructions to perform a Yoga move. The possibilities are endless.</p>
              <p className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15" data-aos="fade-up"
                data-aos-duration={900}>Shopify Integration, live data widgets, lightbox image galleries, carousels, data-capture forms are just a few of the plugins you can add to the chapter overlay.</p>
               <p className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15" data-aos="fade-up"
                data-aos-duration={900}>Need a bespoke plugin? We can build it for you.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-10 mt-lg-20">
          <div className="col-lg-6 col-md-10 col-sm-11">
            <div className="pt-9 pt-lg-0 pr-xs-10 pr-sm-5 pr-md-25 pr-lg-0 pl-xl-16">
              <h3 className="font-size-9 mb-5 mt-10">Mobile Overview</h3>
              <p className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15" data-aos="fade-up"
                data-aos-duration={900}>Fully responsive overlays based on our framework ensures that all content looks perfect on mobile.</p>
              <p className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15" data-aos="fade-up"
                data-aos-duration={900}>The user can also view different chapter overlays using the toolbar navigation buttons.</p>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 col-md-10 col-sm-11 text-center"
            data-aos="fade-right"
            data-aos-duration={500}
          >
            <img src={imgD} alt="Chapter Overview Mobile" className="w-50 box-shadow rounded-5 mobilefull" />
            </div>          
        </div>
      </div>
    </div>
    </div>
  );
};

export default CTA;
